//
// Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors to use across the Theme.
//---------------------------------------------------

// Grayscale
$white: #ffffff !default;
$gray-100: #f5f5f5;
$gray-300: #e7e7e7;
$gray-500: #8c8c8c;
$gray-700: #404040;
$gray-800: #343b43;
$black: #000000 !default;

// Brand colors
$primary: #7f6556;
$secondary: $gray-100;
$accent: #cda28c;
$info: #5695fe;
$success: #33cb81;
$warning: #fc8c3a;
$danger: #ff5252;
$light: $white;
$dark: $gray-800;

// Adding new color to default Bootstrap's $theme-colors map
$theme-colors: (
  "accent": $accent
);

// Theme gradient
$gradient-color-1: $primary !default;
$gradient-color-2: $accent !default;

// Options
// -------------------------------------------------------

$enable-shadows: false;
$enable-rounded: false;
$enable-print-styles: false;

//== Spacing
// -------------------------------------------------------

$spacer: 1rem !default;

//== Body
// -------------------------------------------------------

$body-color: $gray-700;

//== Links
// -------------------------------------------------------

$link-decoration: underline;
$link-hover-color: $primary;
$link-hover-decoration: none;
$link-disabled-color: $gray-500 !default;

// Changing default Bootstrap's container width on "xl" breakpoint
$container-max-widths: (
  xl: 1170px
);

//== Border
// -------------------------------------------------------

$border-color: $gray-300;
$border-light-color: rgba($white, 0.14) !default;
$border-radius: 0.3rem;

//== Fonts
//
// Font, line-height, and color for body text, headings, and more.
// -------------------------------------------------------

// Font families
$font-family-base: "Open Sans", sans-serif;

$font-size-base: 1rem !default; // 16px
$font-size-xl: ($font-size-base * 1.125) !default;
$font-size-lg: $font-size-base;
$font-size-md: ($font-size-base * 0.875) !default;
$font-size-sm: ($font-size-base * 0.75);
$font-size-xs: ($font-size-base * 0.625) !default;

// Headings
$h1-font-size: ($font-size-base * 3);
$h2-font-size: ($font-size-base * 2.5);
$h3-font-size: ($font-size-base * 2.125);
$h4-font-size: ($font-size-base * 1.625);
$h5-font-size: ($font-size-base * 1.3125);
$h6-font-size: ($font-size-base * 1.125);

$headings-margin-bottom: ($spacer * 0.75);
$headings-font-family: "Montserrat", sans-serif;
$headings-font-weight: 900;
$headings-color: $gray-800;

$h1-line-height: 1.2 !default;
$h2-line-height: 1.2 !default;
$h3-line-height: 1.25 !default;
$h4-line-height: 1.3 !default;
$h5-line-height: 1.35 !default;
$h6-line-height: 1.4 !default;

// Display headings
$display404-size: $font-size-base * 19 !default;

$display1-weight: 900;
$display2-weight: 900;
$display3-weight: 900;
$display4-weight: 900;
$display-line-height: 1.1;
$display404-line-height: 1.12;

// Lead
$lead-font-size: ($font-size-base * 1.5);
$lead-font-weight: normal;

// Blockquote
$blockquote-small-color: inherit;
$blockquote-font-size: $font-size-md;
$blockquote-mark-size: ($font-size-base * 1.875) !default;
$blockquote-mark-color: $primary !default;
$blockquote-mark-bg: rgba($primary, 0.1) !default;

// Text optional colors
$text-muted: $gray-500;

//== Box Shadow
// -------------------------------------------------------

$box-shadow: 0 1.375rem 2.25rem -0.75rem rgba($gray-700, 0.13);
$box-shadow-pressed: 0 0.625rem 1.5rem -0.625rem rgba($gray-700, 0.22) !default;
$box-shadow-inset: inset 0 1.25rem 1.875rem -0.75rem rgba($gray-700, 0.12) !default;

// Z-index overrides from BS master list
// -------------------------------------------------------

$zindex-fs-overlay: 9900;
$zindex-modal-backdrop: 1900;
$zindex-modal: 1999;
$zindex-offcanvas: 1100;
$zindex-offcanvas-toggle: 1090;
$zindex-scroll-top-btn: 1050;
$zindex-navbar: 900;

// Image thumbnails
// -------------------------------------------------------

$thumbnail-padding: 0.375rem;
$thumbnail-bg: $white;
$thumbnail-border-width: 1px;
$thumbnail-border-color: darken($border-color, 3%);
$thumbnail-border-radius: 0;
$thumbnail-box-shadow: none;

// Figures
// -------------------------------------------------------

$figure-caption-font-size: ($font-size-base * 0.8125);
$figure-caption-color: $body-color;

// Tables
// -------------------------------------------------------

$table-border-color: $border-color;
$table-head-bg: $gray-100;
$table-head-color: $body-color;
$table-dark-bg: $gray-800;
$table-dark-border-color: $border-light-color;
$table-hover-bg: $gray-100;
$table-dark-hover-bg: rgba($black, 0.1);

//== Forms
// -------------------------------------------------------

$input-padding-x: 0.9375rem;
$input-padding-y: 0.5rem;
$input-padding-x-sm: $input-padding-x;
$input-height-lg: 54px;
$input-height: 44px;
$input-height-sm: 32px;
$input-border-width: 1px;
$input-border-color: darken($border-color, 1%);
$input-color: $body-color;
$input-placeholder-color: $gray-500;
$input-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
  background-color 0.2s ease-in-out;
$input-box-shadow: 0 9px 24px -4px rgba($gray-700, 0.16);
$input-focus-border-color: $white;
$input-disabled-bg: $gray-100;
$form-group-margin-bottom: ($spacer * 1.5);

$custom-control-label-disabled-color: $gray-500;
$custom-file-height: $input-height;
$custom-file-height-inner: ($input-height - 2);
$custom-file-box-shadow: none;
$custom-file-border-color: $input-border-color;
$custom-file-line-height: 1.9rem;
$custom-file-button-bg: $primary;
$custom-file-button-color: $white;
$custom-file-focus-border-color: $input-border-color;
$custom-file-focus-box-shadow: none;
$custom-control-indicator-border-color: darken($border-color, 7%);
$custom-control-indicator-checked-bg: $primary;

$input-group-addon-bg: darken($gray-100, 1%);

//== Buttons
// -------------------------------------------------------

$btn-height-lg: 54px !default;
$btn-height: 44px !default;
$btn-height-sm: 32px !default;
$btn-height-xs: 24px !default;

$btn-padding-y: 0;
$btn-padding-x: 1.75rem;
$btn-line-height: $btn-height;

$btn-padding-y-xs: 0 !default;
$btn-padding-x-xs: 0.75rem !default;
$btn-line-height-xs: $btn-height-xs !default;

$btn-padding-y-sm: 0;
$btn-padding-x-sm: 1.25rem;
$btn-line-height-sm: $btn-height-sm;

$btn-padding-y-lg: 0;
$btn-padding-x-lg: 2rem;
$btn-line-height-lg: $btn-height-lg;

$button-font-size-lg: ($font-size-base * 0.8125) !default;
$button-font-size: $font-size-sm !default;
$button-font-size-sm: ($font-size-base * 0.6875) !default;
$button-font-size-xs: $font-size-xs !default;

$btn-font-weight: bold;
$btn-text-transform: uppercase !default;
$btn-letter-spacing: 0.1em !default;

$btn-transition: all 0.3s !important;

$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-disabled-opacity: 0.5;

// Close
// -------------------------------------------------------

$close-font-weight: normal;
$close-color: $gray-700;
$close-text-shadow: none;

//== Code
// -------------------------------------------------------

$kbd-bg: $gray-800;
$pre-color: $body-color;
$pre-bg: $gray-100 !default;

//== Social Buttons
// -------------------------------------------------------

$social-button-box-size: ($font-size-base * 2.25) !default;
$social-button-margin-x: 0.625rem !default;
$social-button-margin-y: 0.625rem !default;
$social-button-font-size: $font-size-md !default;
$social-button-transition: all 0.3s !default;
$social-btn-box-shadow: 0 0.75rem 1.25rem 0.0625rem rgba($gray-700, 0.11) !default;

//== Navs
// -------------------------------------------------------

$nav-link-padding-y: 0.5625rem;
$nav-link-padding-x: 1.25rem;

$nav-link-font-size: $font-size-sm !default;
$nav-link-font-weight: 600 !default;
$nav-link-text-transform: uppercase !default;
$nav-link-letter-spacing: 0.08em !default;
$nav-link-color: $gray-800 !default;
$nav-link-hover-color: $primary !default;
$nav-link-activer-color: $primary !default;

$nav-tabs-border-width: 0;
$nav-link-transition: all 0.3s !default;

// Breadcrumbs
$breadcrumb-link-size: ($font-size-base * 0.6875) !default;
$breadcrumb-link-color: $primary !default;

//== Navbar
// -------------------------------------------------------

$navbar-brand-width: 75px !default;
$navbar-brand-width-md: 60px !default;

$navbar-padding-x: 0;
$navbar-padding-y: 1.0625rem;
$navbar-brand-padding-y: 0;

$navbar-btn-box-shadow: 0 0.75rem 1.25rem 0.0625rem rgba($gray-700, 0.13) !default;

//== Dropdowns
// -------------------------------------------------------

$dropdown-min-width: 11.875rem;
$dropdown-padding-y: 0;
$dropdown-item-size: ($font-size-base * 0.8125) !default;

$dropdown-link-color: $gray-800;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: transparent;
$dropdown-border-color: $border-color;
$dropdown-box-shadow: 0 0.5rem 1.25rem -0.25rem rgba($gray-700, 0.2);

$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: transparent;

$dropdown-header-color: $gray-500;

//== Breadcrumb
// -------------------------------------------------------

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-margin-bottom: 0.25rem;
$breadcrumb-item-padding: 0.75rem;
$breadcrumb-bg: transparent;
$breadcrumb-font-size: ($font-size-base * 0.6875);
$breadcrumb-divider-color: $primary;
$breadcrumb-divider: "";

//== Badges
// -------------------------------------------------------

$badge-font-size: ($font-size-base * 0.6875);
$badge-font-weight: 600;
$badge-border-radius: 0.125rem;

//== Modal
// -------------------------------------------------------

$modal-inner-padding: 1rem 1.25rem;
$modal-header-padding: 1rem 1.25rem;

$modal-content-border-color: transparent;
$modal-content-border-radius: 0;
$modal-content-box-shadow-xs: 0 0.75rem 1.25rem 0.0625rem rgba($gray-700, 0.09);
$modal-content-box-shadow-sm-up: 0 0.75rem 1.25rem 0.0625rem
  rgba($gray-700, 0.09);

$modal-header-border-color: $border-color;
$modal-footer-border-color: $border-color;

//== Alert
// -------------------------------------------------------

$alert-padding-y: ($spacer * 1.5);
$alert-padding-x: ($spacer * 1.5);
$alert-border-width: 0.3125rem;

//== Toast
// -------------------------------------------------------

$toast-padding-y: 0.375rem;
$toast-border-color: $border-color;
$toast-background-color: $white;
$toast-box-shadow: $box-shadow;
$toast-border-radius: 0;

//== Progress Bars
// -------------------------------------------------------

$progress-height: 2rem;
$progress-bg: $gray-100;
$progress-light-bg: rgba($white, 0.12) !default;
$progress-box-shadow: none;
$progress-bar-box-shadow: 0 0.5rem 1rem 0 rgba($black, 0.15) !default;
$progress-outer-label-size: $font-size-sm !default;
$progress-outer-label-color: lighten($gray-500, 15%) !default;

//== Off-Canvas
// -------------------------------------------------------

$offcanvas-width: 320px !default;
$offcanvas-bg: $white !default;
$offcanvas-box-shadow: 1.375rem 0 2.25rem -0.75rem rgba($gray-700, 0.12) !default;
$offcanvas-box-shadow-reverse: -1.375rem 0 2.25rem -0.75rem rgba($gray-700, 0.12) !default;
$offcanva-toggle-box-shadow: 0 0.75rem 1.175rem 0.075rem rgba($gray-700, 0.2) !default;

//== Fullscreen Overlay
// -------------------------------------------------------

$fs-overlay-padding-x: 0 !default;
$fs-overlay-padding-y: 70px !default;
$fs-overlay-transition: all 0.35s !default;
$fs-overlay-bg: $white !default;
$fs-overlay-close-box-shadow: 0 0.625rem 1.125rem 0 rgba($black, 0.11) !default;

//== Cards
// -------------------------------------------------------

$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

$card-border-color: $border-color;
$card-cap-bg: $white;
$card-bg: $white;
$card-box-shadow: 0 0.75rem 1.25rem 0.0625rem rgba($gray-700, 0.09) !default;
$card-outer-box-shadow: 0 1.1875rem 1.75rem 0.3125rem rgba($gray-700, 0.09) !default;

//== Tooltips
// -------------------------------------------------------

$tooltip-padding-y: 0.35rem;
$tooltip-padding-x: 0.6rem;
$tooltip-bg: darken($gray-800, 5%);
$tooltip-arrow-color: darken($gray-800, 5%);
$tooltip-opacity: 1;

//== Popovers
// -------------------------------------------------------

$popover-font-size: ($font-size-base * 0.8125);
$popover-border-color: $border-color;
$popover-box-shadow: $box-shadow;
$popover-arrow-outer-color: darken($border-color, 4%);
$popover-header-padding-y: 0.6rem;

//== List Group
// -------------------------------------------------------

$list-group-border-color: $border-color;
$list-group-item-padding-y: 1rem !default;

$list-group-active-color: $primary;
$list-group-active-bg: transparent;
$list-group-active-border-color: $border-color;

$list-group-disabled-color: $gray-500;

//== Pagination
// -------------------------------------------------------

$pagination-link-height: 36px !default;
$navigation-link-height: 44px !default;
$pagination-link-color: $gray-700 !default;
$pagination-link-active-color: $primary !default;
$pagination-link-box-shadow: 0 0.75rem 1.25rem 0.0625rem rgba($gray-700, 0.11) !default;

//== Pagination
// -------------------------------------------------------

$jumbotron-bg: $gray-100;

//== Search
// -------------------------------------------------------

$search-box-bg: $white !default;
$autocomplete-box-shadow: 0 0.375rem 1.25rem 0 rgba($gray-700, 0.2) !default;

//== Icon Box
// -------------------------------------------------------

$icon-box-max-width: 400px !default;
$icon-box-icon-size: 70px !default;

//== Carousel
// -------------------------------------------------------

$carousel-navs-size: 48px !default;
$carousel-navs-arrow-size: 1.3125rem !default;
$carousel-navs-bg: $white !default;
$carousel-navs-arrow-color: $gray-700 !default;
$carousel-navs-hover-arrow-color: $primary !default;
$carousel-dots-size: 5px !default;
$carousel-navs-box-shadow: 0 0.625rem 0.9375rem 0 rgba($black, 0.12) !default;

//== Spinners
// -------------------------------------------------------

$spinner-border-width: 0.15em;
$spinner-border-width-sm: 0.1em;

//== Gallery
// -------------------------------------------------------

$gallery-hover-box-shadow: 0 0.5rem 1.5rem 0 rgba($black, 0.26) !default;

//== Block Title
// -------------------------------------------------------

$block-title-samll-size: $font-size-md !default;
$block-title-icon-size: 40px !default;
$block-title-icon-bg: rgba($primary, 0.1) !default;
$block-title-icon-color: $primary !default;
$block-title-icon-font-size: 1.25rem !default;

//== Animated Digits
// -------------------------------------------------------

$animated-digit-font-family: $headings-font-family !default;
$animated-digit-font-size: $h1-font-size !default;
$animated-digit-font-weight: bold !default;

$animated-digit-icon-size: 40px !default;
$animated-digit-icon-bg: rgba($primary, 0.1) !default;
$animated-digit-icon-color: $primary !default;
$animated-digit-icon-font-size: 1.25rem !default;

//== Testimonials
// -------------------------------------------------------

$testimonial-avatar-size: 48px !default;

//== Pricing
// -------------------------------------------------------

$pricing-image-size: 70px !default;

//== Video Player
// -------------------------------------------------------

$video-player-btn-size: 80px !default;
$video-player-btn-bg: $white !default;
$video-player-btn-color: $gray-800 !default;
$video-btn-box-shadow: 0 0.5625rem 1.75rem 0 rgba($gray-700, 0.13) !default;
$video-btn-hover-box-shadow: 0 0.5625rem 1.75rem 0 rgba($primary, 0.2) !default;

//== Widgets
// -------------------------------------------------------

$widget-author-avatar-size: 85px !default;
$widget-post-thumb-size: 60px !default;
$widget-product-thumb-size: 60px !default;

$author-avatar-box-shadow: 0 0.5rem 1.25rem 0 rgba($black, 0.15) !default;

$contact-icon-box-shadow: 0 0.5rem 1.25rem 0 rgba($black, 0.11) !default;

//== Blog
// -------------------------------------------------------

$post-author-avatar-size: 28px !default;
$post-format-size: 34px !default;
$post-format-bg: $white !default;
$post-format-color: $primary !default;
$post-format-icon-size: $font-size-lg !default;
$post-format-box-shadow: 0 0.5625rem 1.125rem 0 rgba($gray-700, 0.14) !default;

$post-title-font-size: $font-size-xl !default;
$post-title-color: $headings-color !default;
$post-title-hover-color: $primary !default;

$post-meta-color: $gray-500 !default;

//== Portfolio
// -------------------------------------------------------

$portfolio-meta-color: rgba($gray-500, 0.75) !default;
$portfolio-meta-font-size: ($font-size-base * 0.6875) !default;
$portfolio-title-font-size: $font-size-xl !default;
$portfolio-title-color: $headings-color !default;
$portfolio-title-hover-color: $primary !default;

//== Shop
// -------------------------------------------------------

$product-card-max-width: 380px !default;
$product-meta-color: rgba($gray-500, 0.75) !default;
$product-meta-hover-color: $gray-700 !default;

$product-title-font-size: $font-size-lg !default;
$product-title-color: $headings-color !default;
$product-title-hover-color: $primary !default;

$product-button-width: 50px !default;
$product-button-height: 40px !default;
$product-button-bg: $white !default;
$product-button-hover-bg: $primary !default;
$product-button-color: $gray-700 !default;
$product-button-hover-color: $white !default;
$product-button-font-size: $font-size-lg !default;
$product-button-box-shadow: 0 0.75rem 1.25rem 0.0625rem rgba($gray-700, 0.11) !default;

$cart-product-thumb-width: 110px !default;

$shop-category-card-max-width: 400px !default;
$shop-category-meta-color: rgba($gray-500, 0.75) !default;
