//
// user-variables.scss
// Use this to overwrite Bootstrap and CreateX variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary: #7f6556;
$secondary: $gray-100;
$accent: #cda28c;
$info: #5695fe;
$success: #33cb81;
$warning: #fc8c3a;
$danger: #ff5252;
